<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="../solution/img/顶部背景.png" alt="" />
    </div>
    <div class="bottomCss">
      <div class="xiangqing">
        <div class="title">智慧高速交通解决方案</div>
        <div class="jieshao">
          智慧高速交通解决方案基于全数字网络，通过在高速公路道路、隧道、收费站、服务区等区域安装高清摄像机及LED诱导屏，对采集到的人、车、时间的数据进行分析、整理及挖掘，构建可视化的高速公路综合管理平台，并充分发挥管理者的管控、监测和协调能力，基于实时路况监测、违法抓拍管理、交通流量控制、交通信息发布等系统，实现高速交通智能化、科学化的管理以及安全高效、方便快捷的智慧出行服务。
        </div>
        <div>
          <img
            style="width: 1186px;"
            src="./img/高速1.png"
            alt=""
          />
        </div>
        <div class="xiaobiaoqian">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >系统组成</span
          >
        </div>
        <div>
          <img
            style="width: 1186px;"
            src="./img/高速2.png"
            alt=""
          />
        </div>
        <div class="xiaobiaoqian">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >系统架构</span
          >
        </div>
        <div>
          <img
            style="width: 1186px; margin-top: 36px; margin-bottom: 64px"
            src="./img/高速3.png"
            alt=""
          />
        </div>
        <div class="xiaobiaoqian">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >方案优势</span
          >
        </div>
        <div>
          <img
            style="width: 1186px; margin-top: 36px; margin-bottom: 64px"
            src="./img/高速4.png"
            alt=""
          />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataBox: [
          {
            text: "城市智慧停车管理服务平台",
          },
          {
            text: "互联网+停车服务系统",
          },
          {
            text: "路内停车管理系统",
          },
          {
            text: "城市停车诱导系统",
          },
          {
            text: "智能立体停车库管理系统",
          },
          {
            text: "智能BMS管理功能",
          },
          {
            text: "违法停车一体化抓拍系统",
          },
        ],
    };
  },
};
</script>

<style scoped>
.bottomCss {
  width: 100%;
  height: 100%;
  background: #f2f5f8;
  display: flex;
  justify-content: center;
}
.xiangqing {
  width: 1314px;
  /* height: 1404px; */
  margin-top: 64px;
  margin-bottom: 88px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 38px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  /* line-height: 0px; */
  margin-top: 54px;
  margin-bottom: 36px;
}
.jieshao {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 38px;
  width: 1186px;
}
.xiaobiaoqian {
  display: flex;
  margin-bottom: 36px;
  margin-top: 48px;
  margin-left: -78.5%;
}
.xiaobiaoqian9 {
  display: flex;
  margin-bottom: 36px;
  margin-top: 48px;
  margin-left: -67%;
}
li::before {
  content: "";
  display: flex;
  width: 9px;
  height: 9px;
  background: #0940fd;
  border-radius: 50%;
  margin: 1%;
}
</style>